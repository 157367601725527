import GATSBY_COMPILED_MDX from "/opt/render/project/src/packages/website/content/guides/how-to-customize-quill.mdx";
import {useLocation} from '@reach/router';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import SEO from '../components/SEO';
import docsItems from '../data/docs';
import guideItems from '../data/guides';
import OctocatIcon from '../svg/octocat.svg';
import slug from '../utils/slug';
import Default from '../components/Default';
import OpenSource from '../components/OpenSource';
import React, {useEffect} from 'react';
import * as styles from './doc.module.scss';
const getPagination = (permalink, items) => {
  const flattenedItems = [];
  const flatItems = i => {
    i.forEach(child => {
      if (child.url.includes('#')) return;
      flattenedItems.push(child);
      if (child.children) {
        flatItems(child.children);
      }
    });
  };
  flatItems(items);
  const index = flattenedItems.findIndex(item => item.url === permalink);
  if (index === -1) return {
    prev: null,
    next: null
  };
  let prev = null;
  let next = null;
  if (index > 0) prev = flattenedItems[index - 1];
  if (index < flattenedItems.length - 1) next = flattenedItems[index + 1];
  return {
    prev,
    next
  };
};
const SidebarItem = ({item}) => {
  const {pathname} = useLocation();
  return React.createElement("li", {
    className: classNames({
      active: pathname.includes(item.url)
    })
  }, React.createElement("a", {
    href: item.url
  }, item.title), item.children && React.createElement("ul", null, item.children.map(child => React.createElement(SidebarItem, {
    key: child.url,
    item: child
  }))));
};
const Doc = ({data, children}) => {
  const {title} = data.mdx.frontmatter;
  const {permalink, pageType} = data.mdx.fields;
  const category = pageType === 'guides' ? 'Guides' : 'Documentation';
  const items = pageType === 'guides' ? guideItems : docsItems;
  const {prev, next} = getPagination(permalink, items);
  useEffect(() => {
    docsearch({
      apiKey: '281facf513620e95600126795a00ab6c',
      indexName: 'quilljs',
      inputSelector: '.search-item input',
      debug: false
    });
  }, []);
  return React.createElement(Default, {
    pageType: pageType
  }, React.createElement("div", {
    id: "docs-wrapper",
    className: "container"
  }, React.createElement("div", {
    className: "row"
  }, React.createElement("div", {
    id: "sidebar-container",
    className: "three columns"
  }, React.createElement("button", {
    className: "sidebar-button"
  }, "Document Navigation"), React.createElement("ul", {
    className: "sidebar-list"
  }, React.createElement("li", {
    className: "search-item"
  }, React.createElement("input", {
    type: "text"
  })), items.map(item => React.createElement(SidebarItem, {
    key: item.url,
    item: item
  })))), React.createElement("div", {
    id: "docs-container",
    className: "nine columns"
  }, React.createElement("div", {
    className: classNames('row', styles.breadcrumbRow)
  }, React.createElement("div", {
    className: styles.breadcrumb
  }, React.createElement("span", null, category, ":"), React.createElement("span", null, title)), React.createElement("a", {
    className: styles.editLink,
    href: data.mdx.fields.githubPath,
    target: "_blank",
    title: "Edit on GitHub"
  }, React.createElement(OctocatIcon), React.createElement("span", null, "Edit on GitHub"))), React.createElement("hr"), React.createElement("article", {
    id: "content-container",
    className: styles.content
  }, React.createElement("h1", {
    id: slug(title)
  }, title), children), React.createElement("div", {
    className: "row",
    id: "pagination-container"
  }, prev && React.createElement("a", {
    className: "prev",
    href: prev.url
  }, React.createElement("span", {
    className: "label"
  }, prev.title), React.createElement("span", {
    className: "arrow"
  }, React.createElement("span", {
    className: "tip"
  }), React.createElement("span", {
    className: "shaft"
  }))), next && React.createElement("a", {
    className: "next",
    href: next.url
  }, React.createElement("span", {
    className: "label"
  }, next.title), React.createElement("span", {
    className: "arrow"
  }, React.createElement("span", {
    className: "tip"
  }), React.createElement("span", {
    className: "shaft"
  })))))), React.createElement("div", {
    className: "row"
  }, React.createElement("hr")), React.createElement(OpenSource)));
};
export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      fields {
        slug
        permalink
        pageType
        githubPath
      }
      frontmatter {
        title
      }
    }
  }
`;
export const Head = ({data}) => React.createElement(React.Fragment, null, React.createElement(SEO, {
  title: data.mdx.frontmatter.title,
  permalink: data.mdx.fields.permalink
}), React.createElement("link", {
  rel: "stylesheet",
  href: "/assets/css/base.css"
}), React.createElement("link", {
  rel: "stylesheet",
  href: "/assets/css/styles.css"
}));
Doc
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Doc, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
